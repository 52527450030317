export const usePendingStore = defineStore("pending", {
  state(): { pending: boolean } {
    return {
      pending: false,
    };
  },

  getters: {
    getPending: (state) => state.pending,
  },

  actions: {
    setPending(pending: boolean) {
      this.pending = pending;
    },
  },
});
